import React  from 'react';
import '../../App.css';
import Cards from '../Cards'
import Footer from '../Footer'
import './products.css'

export default function Products() {
   return (
   
    <body>
 <div className="about-us">
      <div className="about-us-content">
        <h1>About Us</h1>
        <p>
         



Welcome to Kilo Consultants

At Kilo Consultants, we are proud to be a consulting company founded and operated by disabled veterans who have proudly served in the United States Marine Corps. Our mission is to bring the same dedication, discipline, and commitment to excellence that we honed during our military service into the world of consulting.
        </p>
        <h1></h1>
        <p>
        Our journey began with a shared vision – to create a consultancy that embodies the core values we learned as Marines: Honor, Courage, and Commitment. After serving our nation with unwavering dedication, we decided to channel our skills and expertise into making a difference in the civilian sector.
        </p>
        <h1></h1>
        
        <p>
        As a veteran-led company, we bring a unique perspective to every project we undertake. Our military background has instilled in us a sense of responsibility, attention to detail, and a relentless pursuit of excellence. We leverage these qualities to deliver exceptional results for our clients.
        </p>
      </div>
    </div>




<Footer></Footer>
</body>


  );
  
}
