import "../../App.css";
import "../pages/contact.css";


import React, { useRef } from "react";

import { MdAlternateEmail } from "react-icons/md";
import { AiTwotonePhone } from "react-icons/ai";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    

    emailjs
      .sendForm(
        "service_f2jkoen",
        "template_h9w9aet",
        form.current,
        "ElX3vzQZFfWf0UmDg"
      )
     
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          alert("Inquiry Submitted")
        },
        (error) => {
          console.log(error.text);
        }
      
      );
      e.target.reset();
  };
  return (
    <>
    <section id="contact">
      <div className="container contact_container">
        <video src="/videos/video-5.mp4" autoPlay loop muted />
        <div className="contact_options">
          <article className="contact-option">
            <MdAlternateEmail className="contact_option-icon" />
            <h4>Email</h4>
            <h5>operations@kiloconsultants.com</h5>
          </article>

          <article className="contact-option">
            <AiTwotonePhone className="contact_option-icon" />
            
          </article>
        </div>

        <form onSubmit={sendEmail} ref={form}>
           <strong className='contact-us' style={{paddingBottom:"15%"}}></strong>
          <input type="text" name='name' placeholder=" name" required id='name' />
          <input type="email" name='email' placeholder=" email" required />
          <textarea
            name='message'
            placeholder="Message"
            required
          />
          <button type="submit" className="btn btn-primary" >
            Send Message
          </button>
        </form>
      </div>
    
    </section>
   
    </>
  );

};

export default Contact;
